import '../styles/issues.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import december_2023_cover from '../images/December 2023 cover.webp'
import september_2023_cover from '../images/September 2023 cover.webp'
import june_2023_cover from '../images/June 2023 cover.webp'
import dec_cover from '../images/issue.png';
import sept_cover from '../images/sept-cover.webp';
import june_cover from '../images/june-cover.webp';
import march_cover from '../images/march-cover.webp'
import march_cover2024 from '../images/March 2024 cover.webp'
import sept2024cover from '../images/sept-2024-cover.webp'
import march2025cover from '../images/march-2025-cover.webp'
import supplement_cover from '../images/supplement cover.webp'
import dec2024cover from '../images/winter-2024-cover.webp';

const Issues = ({ setNotOptions, mobile, setPodcastLogo, auth }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);
  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const titleStyle = mobile ? 'mobile-title' : 'events-title';
  const issuesContainer = mobile
    ? 'issues-container-mobile'
    : 'issues-container';

    const coverSize = mobile ? 'covers' : 'covers-desktop';
    const coverSizeSupplement = mobile ? 'covers-supplement' : 'covers-desktop-supplement';

// if (auth) {

  return (
    <>
    <section className={issuesContainer}>

    <div className='cover-divs'>
        {/* <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_december_2023_01-01-2024_?fr=sODk4ZDY5MjczNDc'> */}
          <img src={march2025cover} className={coverSize}></img>
        {/* </a> */}
        <p className='issue-captions'>
        {/* Current Issue
        <br />
          <br /> */}
          March <br/> 2025
        </p>
        
      </div>

      <div className='cover-divs'>
        {/* <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_december_2023_01-01-2024_?fr=sODk4ZDY5MjczNDc'> */}
          <img src={dec2024cover} className={coverSize}></img>
        {/* </a> */}
        <p className='issue-captions'>
        Current Issue
        <br />
        
          December<br/> 2024
        </p>
        
      </div>

    <div className='cover-divs'>
        {/* <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_december_2023_01-01-2024_?fr=sODk4ZDY5MjczNDc'> */}
          <img src={sept2024cover} className={coverSize}></img>
        {/* </a> */}
        <p className='issue-captions'>
        
          September <br/> 2024
        </p>
        
      </div>

    <div className='cover-divs'>
        <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_march_2024?fr=sYjA5Yjc0NTE4NDQ'>
          <img src={march_cover2024} className={coverSize}></img>
        </a>
        <p className='issue-captions'>
         
          March<br/> 2024
        </p>
        
      </div>
    <div className='cover-divs'>
        <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_december_2023_01-01-2024_?fr=sODk4ZDY5MjczNDc'>
          <img src={december_2023_cover} className={coverSize}></img>
        </a>
        <p className='issue-captions'>
          
          December <br/> 2023
        </p>
        
      </div>

       <div className='cover-divs'>
        {/* <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_march_2023?fr=sZGQ5YjU5NTY2MjE'> */}
          <img src={september_2023_cover} className={coverSize}></img>
        {/* </a> */}
        <p className='issue-captions'>
          
          September - October <br/> 2023
        </p>
        
      </div>
      <div className='cover-divs'>
        <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_june_2023?fr=sOTk5MTY5MjczNDc'>
          <img src={june_2023_cover} className={coverSize}></img>
        </a>
        <p className='issue-captions'>
          
          June - July<br/> 2023
        </p>
        {/* <div className='supplement-cover'><img src={supplement_cover} className={coverSizeSupplement}></img><p className='issue-captions'>AAWC <br/><br/>Salt Lake City 2022 <br/><br/>Supplement</p></div> */}
      </div>
     
    </section>
    <div className='issue-page-spacer'></div>
    <section className={issuesContainer}>

      
       <div className='cover-divs'>
        <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_march_2023?fr=sZGQ5YjU5NTY2MjE'>
          <img src={march_cover} className={coverSize}></img>
        </a>
        <p className='issue-captions'>
          {/* Current Issue
          <br />
          <br /> */}
          March - April <br/> 2023
        </p>
        
      </div>
      <div className='cover-divs'>
        <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_december_2022_d8889ecdfc1923?fr=sNTJkYzU3MDAzMzU'>
          <img src={dec_cover} className={coverSize}></img>
        </a>
        <p className='issue-captions'>
          
          December - January<br/> 2022
        </p>
        {/* <div className='supplement-cover'><img src={supplement_cover} className={coverSizeSupplement}></img><p className='issue-captions'>AAWC <br/><br/>Salt Lake City 2022 <br/><br/>Supplement</p></div> */}
      </div>
      <div className='cover-divs'>
        <a href='https://issuu.com/woundmasterclass/docs/wound_masterclass_-_september_2022?fr=sMjhiMjU3MDAzMzU'>
          <img src={sept_cover} className={coverSize}></img>
        </a>
        <p className='issue-captions'>September - October<br/> 2022</p>
      </div>
      <div className='cover-divs'>
        <a href='https://issuu.com/woundmasterclass/docs/wound-masterclass-june-2022?fr=sODc3OTU3MDAzMzU'>
          <img src={june_cover} className={coverSize}></img>
        </a>
        <p className='issue-captions'>May - June<br/> 2022</p>
      </div>
    </section>
    ,/</>
  );
        
  //       else {
          
  // return (
  //   <>
  //   <section className={issuesContainer}>

  //   <div className='cover-divs'>
  //       <a href='https://woundmasterclass.com/Register'>
  //         <img src={december_2023_cover} className={coverSize}></img>
  //       </a>
  //       <p className='issue-captions'>
  //         Current Issue
  //         <br />
  //         <br />
  //         December <br/> 2023
  //       </p>
        
  //     </div>

  //      <div className='cover-divs'>
  //       <a href='https://woundmasterclass.com/Register'>
  //         <img src={september_2023_cover} className={coverSize}></img>
  //       </a>
  //       <p className='issue-captions'>
         
  //         September - October <br/> 2023
  //       </p>
        
  //     </div>
  //     <div className='cover-divs'>
  //       <a href='https://woundmasterclass.com/Register'>
  //         <img src={june_2023_cover} className={coverSize}></img>
  //       </a>
  //       <p className='issue-captions'>
          
  //         June - July<br/> 2023
  //       </p>
  //       {/* <div className='supplement-cover'><img src={supplement_cover} className={coverSizeSupplement}></img><p className='issue-captions'>AAWC <br/><br/>Salt Lake City 2022 <br/><br/>Supplement</p></div> */}
  //     </div>
     
  //   </section>
  //   <div className='issue-page-spacer'></div>
  //   <section className={issuesContainer}>

      
  //      <div className='cover-divs'>
  //       <a href='https://woundmasterclass.com/Register'>
  //         <img src={march_cover} className={coverSize}></img>
  //       </a>
  //       <p className='issue-captions'>
  //         {/* Current Issue
  //         <br />
  //         <br /> */}
  //         March - April <br/> 2023
  //       </p>
        
  //     </div>
  //     <div className='cover-divs'>
  //       <a href='https://woundmasterclass.com/Register'>
  //         <img src={dec_cover} className={coverSize}></img>
  //       </a>
  //       <p className='issue-captions'>
          
  //         December - January<br/> 2022
  //       </p>
  //       {/* <div className='supplement-cover'><img src={supplement_cover} className={coverSizeSupplement}></img><p className='issue-captions'>AAWC <br/><br/>Salt Lake City 2022 <br/><br/>Supplement</p></div> */}
  //     </div>
  //     <div className='cover-divs'>
  //       <a href='https://woundmasterclass.com/Register'>
  //         <img src={sept_cover} className={coverSize}></img>
  //       </a>
  //       <p className='issue-captions'>September - October<br/> 2022</p>
  //     </div>
  //     <div className='cover-divs'>
  //       <a href='https://woundmasterclass.com/Register'>
  //         <img src={june_cover} className={coverSize}></img>
  //       </a>
  //       <p className='issue-captions'>May - June<br/> 2022</p>
  //     </div>
  //   </section>
  //   ,/</>
  // );
  //       }
};

export default Issues;
